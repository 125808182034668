<template>
  <v-avatar
    :size="size"
    tile
  >
    <vue-svg-gauge
      :start-angle="-115"
      :end-angle="115"
      :value="value"
      :separator-step="1"
      :gauge-color="[
        { offset: 0, color: '#00814E'},
        { offset: 14.3, color: '#4CB548'},
        { offset: 28.6, color: '#C5D835'},
        { offset: 42.9, color: '#FFCC07'},
        { offset: 57.2, color: '#F7973A'},
        { offset: 71.5, color: '#EA2427'},
        { offset: 85.8, color: '#AC1F24'}
      ]"
      xscale-interval="10"
      :inner-radius="55"
      :max="7"
    >
      <div
        class="inner-text"
        :xstyle="'color:' + colorGauge(value)"
      >
        {{ text ? text : value }}
      </div>
    </vue-svg-gauge>
  </v-avatar>
</template>
<script>
  import { VueSvgGauge } from 'vue-svg-gauge'

  export default {
    components: {
      VueSvgGauge,
    },
    props: {
      value: {
        type: Number,
        default: null,
      },
      text: {
        type: [Number, String],
        default: null,
      },
      size: {
        type: Number,
        default: 44,
      },
    },
    methods: {
      colorGauge (weight) {
        var color1 = weight > 50 ? [0, 204, 0] : [255, 165, 0]
        var color2 = weight > 50 ? [255, 165, 0] : [255, 0, 0]
        var p = weight > 50 ? (weight - 50) / 50 : weight / 50
        var w = p * 2 - 1
        var w1 = (w / 1 + 1) / 2
        var w2 = 1 - w1
        var rgb = [
          Math.round(color1[0] * w1 + color2[0] * w2),
          Math.round(color1[1] * w1 + color2[1] * w2),
          Math.round(color1[2] * w1 + color2[2] * w2),
        ]
        return 'rgb(' + rgb.join() + ')'
      },
    },
  }
</script>
<style lang="scss" scoped>
.inner-text {
  height: 100%;
  width: 100%;
  // font-size: 4.2em;
  // padding-top: 1em;
  font-weight: 600;
  text-shadow: 0 0 #ccc;
  font-size: 3.9em;
  padding-top: 33%;

  span {
    max-width: 100px;
  }
}

.v-list-item--disabled {
  .gauge {
    opacity: 0.5;
  }
}
</style>
