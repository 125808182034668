var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-avatar',{attrs:{"size":_vm.size,"tile":""}},[_c('vue-svg-gauge',{attrs:{"start-angle":-115,"end-angle":115,"value":_vm.value,"separator-step":1,"gauge-color":[
      { offset: 0, color: '#00814E'},
      { offset: 14.3, color: '#4CB548'},
      { offset: 28.6, color: '#C5D835'},
      { offset: 42.9, color: '#FFCC07'},
      { offset: 57.2, color: '#F7973A'},
      { offset: 71.5, color: '#EA2427'},
      { offset: 85.8, color: '#AC1F24'}
    ],"xscale-interval":"10","inner-radius":55,"max":7}},[_c('div',{staticClass:"inner-text",attrs:{"xstyle":'color:' + _vm.colorGauge(_vm.value)}},[_vm._v(" "+_vm._s(_vm.text ? _vm.text : _vm.value)+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }